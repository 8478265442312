
import { defineComponent } from 'vue'
import { Vue3Lottie } from 'vue3-lottie'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import Delete from '@/assets/svg/delete.svg?inline'
import Phone from '@/assets/svg/phone.svg?inline'
import moment from 'moment'

export default defineComponent({
  name: 'VisitDetailsSection',
  data () {
    return {
      isLoading: false,
      isDeletePopup: false,
      isModalCallRequest: false,
      appointmentData: {},
      visitId: null,
      isHistory: false,
      errorCallCheck: null
    }
  },
  components: {
    LottieAnimation: Vue3Lottie,
    DefaultPopup,
    Delete,
    Phone
  },
  mounted () {
    this.appointmentId = this.$route.path
    const url = this.appointmentId
    const parts = url.split('/')
    const id = parts[parts.length - 1]
    this.getAppointments(id)
  },
  methods: {
    getAppointments (id) {
      this.$store.dispatch('property/getAppointment', id).then(
        (res) => {
          this.appointmentData = res.data
          this.isLoading = false
        },
        (error) => {
          this.errorDevices = error.response
          this.isLoading = false
        }
      )
    },
    handleCloseRefreshDevicePopup () {
      this.isDeletePopup = false
      this.isHistory = false
    },
    formatDate (date) {
      return moment(date).format('DD-MM-YYYY')
    },
    openDeleteAppointments (visitId, isHistory) {
      this.visitId = visitId
      this.isDeletePopup = true
      this.isHistory = isHistory
      console.log(this.isHistory, 'this.isHistory')
    },
    saveCallRequestPopup (visitId) {
      this.visitId = visitId
      this.isModalCallRequest = true
    },
    handleCloseModalCallRequest () {
      this.isModalCallRequest = false
    },
    deleteAppointment () {
      this.isLoading = true
      this.$store.dispatch('devices/deleteAppointments', this.visitId).then(
        (res) => {
          this.$router.push('/dashboard/main')
          this.isModalDelete = false
          this.isLoading = false
        },
        (error) => {
          this.errorDevices = error.response
          this.isLoading = false
        }
      )
    },
    cancelAppointments () {
      this.isLoading = true
      this.$store.dispatch('devices/cancelAppointments', this.visitId).then(
        () => {
          this.$router.push('/dashboard/main')
          this.isModalDelete = false
          this.isLoading = false
        },
        (error) => {
          this.errorDevices = error.response
          this.isLoading = false
        }
      )
    },
    saveCallRequest () {
      this.isLoading = true
      this.$store.dispatch('devices/saveCallRequest', this.visitId).then(
        () => {
          this.getAppointments()
          this.isModalCallRequest = false
          this.visitId = null
          this.isLoading = false
        },
        (error) => {
          this.errorCallCheck = error.response.data.message
          this.visitId = null
          this.isLoading = false
        }
      )
    }
  }
})
